import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { tr } from 'mmfintech-commons'

import { PaymentMethodButton, PaymentMethodImage } from '../PaymentMethodButton'
import {
  PaymentMethodsContainer,
  PaymentMethodsEmpty,
  PaymentMethodsTitle,
  PaymentMethodsWrapper,
  SingleMethodName,
  SingleMethodWrapper
} from './SelectMethod.styled'

export const SelectMethod = ({ className, method, setMethod }) => {
  const { checkoutOptions } = useSelector(
    ({ checkout: { checkoutOptions } }) => ({
      checkoutOptions
    }),
    shallowEqual
  )

  useEffect(() => {
    if (Array.isArray(checkoutOptions) && checkoutOptions.length === 1) {
      setMethod(checkoutOptions[0].paymentMethod)
    }
    // eslint-disable-next-line
  }, [checkoutOptions])

  if (Array.isArray(checkoutOptions) && checkoutOptions.length) {
    if (checkoutOptions.length > 1) {
      return (
        <PaymentMethodsWrapper>
          <PaymentMethodsTitle>{tr('CHECKOUT.PAYMENT.METHOD_LABEL', 'Select payment method')}</PaymentMethodsTitle>
          <PaymentMethodsContainer className={className}>
            {checkoutOptions.map(option => (
              <PaymentMethodButton
                key={option.paymentMethod}
                name={option.paymentMethod}
                selected={method === option.paymentMethod}
                onClick={() => setMethod(option.paymentMethod)}
              />
            ))}
          </PaymentMethodsContainer>
        </PaymentMethodsWrapper>
      )
    }

    if (method) {
      return (
        <SingleMethodWrapper>
          <SingleMethodName>{tr('METADATA.PAYMENT_OPTIONS.' + method, method)}</SingleMethodName>
          <PaymentMethodImage method={method} className='icon' />
        </SingleMethodWrapper>
      )
    } else {
      return null
    }
  }

  return (
    <PaymentMethodsWrapper>
      <PaymentMethodsEmpty>{tr('CHECKOUT.PAYMENT.NO_METHODS', 'No payment methods available')}</PaymentMethodsEmpty>
    </PaymentMethodsWrapper>
  )
}
