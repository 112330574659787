import cn from 'classnames'

import { tr } from 'mmfintech-commons'

import { PaymentMethodImage } from './PaymentMehtodImage'
import { ButtonWrapper, PaymentMethodChecked } from './PaymentMethodButton.styled'

import checkedIcon from '../../assets/icons/checked.svg'

export const PaymentMethodButton = ({ name, selected, onClick }) => (
  <ButtonWrapper className={cn({ selected })} onClick={onClick} data-test='button-payment-method'>
    <PaymentMethodImage method={name} className={cn({ selected })}>
      {selected && (
        <PaymentMethodChecked>
          <img src={checkedIcon} alt='' />
        </PaymentMethodChecked>
      )}
    </PaymentMethodImage>
    {tr('METADATA.PAYMENT_OPTIONS.' + name, name)}
  </ButtonWrapper>
)
