// noinspection SpellCheckingInspection

import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;

  width: 100%;
  max-width: 13rem;
  padding: 0;

  .use-grid & {
    max-width: unset;
  }

  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  color: #808080;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  text-align: center;

  &.selected {
    color: #000000;
  }

  :hover {
    color: #000000;
  }
`

export const PaymentMethodChecked = styled.span`
  position: absolute;
  top: 0;
  right: -10px;
  transform: translate(2px, -8px);
`
